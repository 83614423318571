import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { Tabs } from "antd";
import GeneralTab from "./GeneralTab";
import { Link, useLocation } from "react-router-dom";
import db from "../../Database/db";
import InvoicesAndPayments from "../tabs/InvoiceandPaymant";
import Pricing from "../tabs/Pricing";


const EditOrg = () => {
  const [data, setData] = useState({});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    const organizationData = async (id) => {
      const organizationID = await db.organization.get(parseInt(id));
      console.log(organizationID);
      setData(organizationID);
    };
    organizationData(id);
  }, [id]);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onTap = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "General Information",
      children: <GeneralTab />,
    },
    {
      key: "2",
      label: "Invoice And Payments",
      children: <InvoicesAndPayments/>,
    },
    {
      key: "3",
      label: "Pricing",
      children: <Pricing/>,
    },
  ];

  return (
    <>
      <Button type="primary" style={{top:"50px", left:"170px", display:"flex"}}>
      <Link type="primary" to="/">
        Back
      </Link>
      </Button>
      <section
        style={{
          border: "1px solid rgb(239, 242, 255)",
          width: "80%",
          marginLeft: "150px",
          marginTop: "50px",
          marginBottom: "20px",
          backgroundColor: "white",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
        }}
      >
        <div style={{ margin: "10px" }}></div>
        <div style={{ marginLeft: "20px" }}>
          <h3>Organization Details</h3>
          <Row gutter={24}>
            <Col span={4}>
              <Avatar shape="square" size={64} icon={<UserOutlined />} />
            </Col>
            <Col span={10} style={{ alignItems: "center" }}>
              <h4>{data?.nom}</h4>
              <p>TÉLÉPHONE : {data?.portable_responsable_legal}</p>
              <p>EMAIL : {data?.email}</p>
            </Col>
            <Col span={10}>
              <p>MONTANTS FACTURABLES:N/A</p>
              <p>MONTANTS DÛS: N/A</p>
              <div style={{ display: "flex" }}>
                <p>STATUS</p>
                <Switch
                  defaultChecked
                  onChange={onChange}
                  style={{ alignSelf: "center", marginLeft: "10px" }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onTap}
          style={{ marginLeft: "10px" }}
        />
      </section>
    </>
  );
};

export default EditOrg;
