import React, { useState } from "react";
import { Table } from "antd";
import "./TableComponent.css";
const TableComponent = ({
  columns,
  dataSource,
  isLoading,
  totalPage,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
}) => {
  const [searchText, setSearchText] = useState("");

  const filteredData = dataSource?.filter((record) => {
    const values = Object.values(record);
    const strValues = values?.map((recordVal) =>
      recordVal ? recordVal.toString().toLowerCase() : ""
    );
    return strValues.some((text) => text.includes(searchText.toLowerCase()));
  });

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="display-elements">
          <span>Display</span>
          <select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span>Elements</span>
        </div>
        <div className="patient-search">
          {/* Input for search */}
          <input
            type="search"
            id="patient-search-input"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={filteredData.map((record) => ({ ...record, key: record.id }))}
          onChange={(pagination, filters, sorter) => {
            setCurrentPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
          scroll={{ x: 50 }}
          size="large"
          loading={isLoading}
          pagination={{
            pageSize: pageSize,
            total: totalPage,
            current: currentPage,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </>
  );
};

export default TableComponent;
