import { Button, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../context/MyContext";
import db from "../../Database/db";
import TableComponent from "../TableComponent/TableComponent";

const InvoiceandPaymant = () => {
  const [searchText, setSearchText] = useState("");
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [pageSize, setPageSize] = useState(10);
     const [currentPage, setCurrentPage] = useState(1);
  const { data, setData } = useContext(MyContext);
    const token = localStorage.getItem("token");
    const [total, setTotal] = useState("");
  const [changed, setChanged] = useState(false);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (a.date || "").localeCompare(b.date || ""),
    },
    {
      title: "Number",
      dataIndex: "codefacture",
    //   render: (codefacture) => codefacture?.number || "",
      sorter: {
        compare: (a, b) =>
          (a.codefacture?.number || "").localeCompare(b.codefacture?.number || ""),
      },
    },
    {
      title: "ENVOYEEA",
      dataIndex: "nom",
      sorter: {
        sorter: (a, b) => (a.nom || "").localeCompare(b.nom || ""),
      },
    },
    {
      title: "BY",
      dataIndex: "destinataire",
      sorter: {
        sorter: (a, b) =>
          (a.destinataire || "").localeCompare(b.destinataire || ""),
      },
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      sorter: {
        sorter: (a, b) => (a.amount || "").localeCompare(b.amount || ""),
      },
    },
    {
      title: "Status",
      dataIndex: "statut",
      sorter: {
        compare: (a, b) => a?.statut - b?.statut,
        multiple: 1,
      },
      render: (text) => {
        if (text === null) {
          return null;
        }

        const statusText = text === 1 ? "Paid" : "Awaiting Payment";
        return (
          <div
            style={{
              backgroundColor: text === 1 ? "#137b4d" : "#d32f2f",
              color: "white",
              borderRadius: "25px",
              textAlign: "center",
            }}
          >
            {statusText}
          </div>
        );
      },
    },
    {
      title: "OPTION",
      dataIndex: "option",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button>Invoice</Button>
          <Button>Payment</Button>
        </div>
      ),
    },
  ];

//   const filteredData = dataSource?.filter((record) => {
//     const values = Object.values(record);
//     const strValues = values?.map((recordVal) =>
//       recordVal ? recordVal.toString().toLowerCase() : ""
//     );
//     return strValues.some((text) => text.includes(searchText.toLowerCase()));
//   });

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    const queryParams = `?offset=${
      (currentPage - 1) * pageSize
    }&limit=${pageSize}`;

    const fetchData = () => {
      fetch(
        `http://54.174.219.75:2001/organization/invoice-payments/270${queryParams}`,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          const firstTenData = res?.data.slice(0, 10);
          setData(firstTenData);
          setTotal(res?.total);
          db.organization.bulkPut(firstTenData);
        })
        .catch((err) => {
          console.log(err);
          db.organization.toArray().then((data) => {
            setData(data);
          });
        });
    };

    if (navigator.onLine) {
      fetchData();
    } else {
      db.organization.toArray().then((data) => {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setData(sortedData);
      });
    }
    // eslint-disable-next-line
  }, [pageSize, currentPage, token, changed]);

  return (
    <>
      <div>
        <strong>{isOnline ? "Online" : "Please go Online"}</strong>
        <TableComponent
          columns={columns}
          dataSource={data}
          totalPage={total}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
    </>
  );
};

export default InvoiceandPaymant;
