import React, { useState } from "react";

// css
import "./AddOrganization.css";

import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import db from "../../Database/db";
import addDB from "../../Database/offlineAddDB";
import { toast } from "react-toastify";

const { Option } = Select;

const price = [
  {
    id: 3,
    name: "hors-category",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 2,
    name: "publique",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 1,
    name: "privé",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
];

const types = [
  {
    id: 7,
    name: "IMP",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 6,
    name: "Assurance",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 5,
    name: "Fournisseur Pharmacie",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 4,
    name: "Pharmacie",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 3,
    name: "Clinique",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 2,
    name: "Polyclinique",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
  {
    id: 1,
    name: "Laboratoire d'Analyses",
    status: 1,
    added_by: null,
    updated_by: null,
    createdAt: null,
    updatedAt: null,
  },
];

const AddOrganization = ({ handleClose, opened }) => {
  const token = localStorage.getItem("token");


  const [phoneNumber, setPhoneNumber] = useState("");

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
  };

  const formatDataForApi = (formData) => {
    return {
      nom: formData.name,
      type_details: formData.type ?{id: formData.type, name: types.find(option=> option.id == formData.type).name} : "",
      adresse: formData.address,
      status: 1,
      createdAt: "22/04/2024 05:30",
      is_light: 0,
      email: formData.email || "",
      portable_responsable_legal: formData.phone || "",
      pricing_category: formData.pricing_category || "",
    };
  };

  const saveOfflineData = async (formData) => {
    try {
      await db.organization.add(formData);
      handleClose();
    } catch (error) {
      console.error("Error saving data offline:", error);
    }
  };

  const onFinish = async (values) => {
    const formData = {
      ...values,
      phone: phoneNumber,
    };

    const formatedData = formatDataForApi(formData);
    const isOnline = navigator.onLine;

    if (isOnline) {
      try {
        await fetch(`http://54.174.219.75:2001/organization/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });
        toast.success("Successfully Added")
        handleClose();
        // console.log(formData);
      } catch (error) {
        console.error("Error calling API:", error);
      }
    } else {
      // console.log(formatedData);
      await addDB.offlineData.add({ data: formData, isSync: 0 });
      saveOfflineData(formatedData);
      toast.success("Successfully Added To Database");
    }
  };

  return (
    <Modal
      open={opened}
      title={"Ajouter sous-traitant"}
      onOk={handleClose}
      onCancel={handleClose}
      footer={[]}
      centered
      width={600}
    >
      <Form
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={onFinish}
        // onValuesChange={handleInputChange}
      >
        {/* Organization Name and Organization Type*/}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              className="form-field"
              name="name"
              label="Organization name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              className="form-field"
              name="type"
              label="Type"
              rules={[{ required: true }]}
            >
              <Select allowClear style={{ marginBottom: 10 }}>
                {types?.map((option) => (
                  <Option value={option.id} key={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Telephone and Email*/}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Telephone"
              rules={[{ required: true }]}
            >
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                className="phone-input"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="form-field"
              name="email"
              label="Email"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Pricing */}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="form-field"
              name="pricing_category"
              label="Pricing"
              rules={[{ required: true }]}
            >
              <Select allowClear style={{ marginBottom: 10 }}>
                {price?.map((option) => (
                  <Option value={option.id} key={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Address */}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="form-field"
              name="address"
              label="Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Country and Region */}
        {/* <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              className="form-field"
              name="country"
              label="Country"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                onChange={(e) => setSelectedCountry(e)}
                disabled={Edit}
                style={{ marginBottom: 10 }}
              >
                {countries?.map((country) => (
                  <Option value={country.id} key={country.id}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {selectedCountry && (
            <Col span={12}>
              <Form.Item
                className="form-field"
                name="region"
                label="Region"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  onChange={(e) => setSelectedRegion(e)}
                  style={{ marginBottom: 10 }}
                >
                  {region?.map((region) => (
                    <Option value={region.id} key={region.id}>
                      {region.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        {selectedRegion && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="form-field"
                name="district"
                label="District"
                rules={[{ required: true }]}
              >
                <Select allowClear style={{ marginBottom: 10 }}>
                  {district?.map((district) => (
                    <Option value={district.id} key={district.id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )} */}

        {/* Button */}
        <div
          style={{
            justifyContent: "center",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddOrganization;
