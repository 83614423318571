import React, { useState } from 'react';
import MyContext from './MyContext';

const MyProvider = ({ children }) => {

  const [data, setData] = useState([]);
  const[auth, setAuth] = useState({});


  return (
    <MyContext.Provider value={{ data, setData, auth, setAuth }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
