import React, { useContext, useEffect, useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";

import db from "../../Database/db";
import TableComponent from "../TableComponent/TableComponent";

import "./users.css";
import AddOrganization from "../../Modal/AddOrganization/AddOrganization";
import MyContext from "../../context/MyContext";
import addDB from "../../Database/offlineAddDB";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Users = () => {
  const { data, setData, setAuth} = useContext(MyContext);
  const token = localStorage.getItem("token");
  const [total, setTotal] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showAddModal, setShowAddModal] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [offlineData, setOfflineData] = useState([]);

  const [changed, setChanged] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "nom",
      sorter: (a, b) => (a.nom || "").localeCompare(b.nom || ""),
    },
    {
      title: "Type",
      dataIndex: "type_details",
      render: (type_details) => type_details?.name || "",
      sorter: {
        compare: (a, b) =>
          (a.type_details?.name || "").localeCompare(
            b.type_details?.name || ""
          ),
        multiple: 1,
      },
    },
    {
      title: "Address",
      dataIndex: "adresse",
      sorter: {
        compare: (a, b) => a.adresse.localeCompare(b.adresse),
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a?.status - b?.status,
        multiple: 1,
      },
      render: (text) => {
        if (text === null) {
          return null;
        }

        const statusText = text === 1 ? "Active" : "In-Active";
        return (
          <div
            style={{
              backgroundColor: text === 1 ? "#137b4d" : "#d32f2f",
              color: "white",
              borderRadius: "25px",
              textAlign: "center",
            }}
          >
            {statusText}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: {
        compare: (a, b) => {
          const statusA = parseInt(a.status);
          const statusB = parseInt(b.status);

          return statusA - statusB;
        },
        multiple: 1,
      },
    },
    {
      title: "Relation",
      dataIndex: "is_light",
      sorter: {
        compare: (a, b) => a?.is_light - b?.is_light,
        multiple: 1,
      },
      render: (text) => {
        if (text === null) {
          return null;
        }

        const statusText = text === 1 ? "Source" : "Produce";
        return <div>{statusText}</div>;
      },
    },
    {
      title: "Modify",
      dataIndex: "modifier",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to={`/org-edit?id=${record?.id}`}>
            <FontAwesomeIcon icon={faPencil} color="#667fa0" />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const uploadORGtoAPI = async () => {
    for (const dataItem of offlineData) {
      if (dataItem?.isSync === 0) {
        try {
          const res = await fetch(
            `http://54.174.219.75:2001/organization/add`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(dataItem.data),
            }
          );
          const response = await res.json();
          if (response.status === 1) {
            await addDB.offlineData.update(dataItem.id, { isSync: 1 });
            setOfflineData((prevData) =>
              prevData.map((item) =>
                item.id === dataItem.id ? { ...item, isSync: 1 } : item
              )
            );
            // alert(`Item with id ${dataItem.id} synced successfully.`);
            toast.success("Successfully Added To Server")
            setChanged(true);
          }
        } catch (error) {
          console.log();
        }
      }
    }
  };

  useEffect(() => {
    if (isOnline) {
      addDB.offlineData.toArray().then((data) => {
        setOfflineData(data);
      });
      // uploadORGtoAPI();
    }
  }, [isOnline]);

  // eslint-disable-next-line
  useEffect(() => {
    uploadORGtoAPI();
    // eslint-disable-next-line
  }, [isOnline, offlineData]);

  useEffect(() => {
    const queryParams = `?offset=${
      (currentPage - 1) * pageSize
    }&limit=${pageSize}`;

    const fetchData = () => {
      fetch(`http://54.174.219.75:2001/organization${queryParams}`, {
        "Content-Type": "application/json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.status === 0) {
            setAuth(false)
            localStorage.clear()
            return
          }
          const firstTenData = res?.data?.slice(0, 10);
          setData(firstTenData);
          setTotal(res?.total);
          db.organization.bulkPut(firstTenData);
        })
        .catch((err) => {
          console.log(err);
          db.organization.toArray().then((data) => {
            setData(data);
          });
        });
    };

    if (navigator.onLine) {
      fetchData();
    } else {
      db.organization.toArray().then((data) => {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setData(sortedData);
      });
    }
  // eslint-disable-next-line
  }, [pageSize, currentPage, token, showAddModal, changed]);

  // Opning modal handler
  const showAddOrgModal = () => {
    setShowAddModal(true);
  };

  // closing modal handler
  function handleClose() {
    setShowAddModal(false);
  }

  return (
    <div className="container">
      <div className="header">
        <p className="orgName">Organization</p>
        <p>{isOnline ? "Online" : "Offline"}</p>

        <Button className="add-btn" onClick={showAddOrgModal} type="primary">
          Add
        </Button>
      </div>

      <TableComponent
        columns={columns}
        dataSource={data}
        totalPage={total}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {showAddModal && (
        <AddOrganization handleClose={handleClose} opened={showAddModal} />
      )}
    </div>
  );
};

export default Users;
