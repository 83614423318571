import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, createHashRouter, RouterProvider } from "react-router-dom";
// import NavBar from "./navigation/Navbar";

// import Home from "./components/Home/Home";
// import About from "./components/About/About";
import Users from "./components/Users/Users";
// import MyProvider from "./context/MyProvider";
import EditOrg from "./components/EditOrg/EditOrg";
import MyContext from "./context/MyContext";
import Login from "./components/login/Login";

const router = createHashRouter([{
  children: [
    {
      path: "/",
      element: <Users/>
    },
    {
      path: "/org-edit",
      element: <EditOrg/>
    }
  ]
}])

function App() {
  const { auth } = useContext(MyContext);
  const[token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken !== token) {
      setToken(storedToken);
    }
  }, [token, auth]);

  return (
    <>
      {!token ? (
        <Login />
      ) : (
        // <OtpPage />
        <RouterProvider router={router} />
      )}
      {/* <Router>
        <Routes>
           <Route path="/" element={<Users />} />
           <Route path="/org-edit" element={<EditOrg />} />
         </Routes>
       </Router> */}
      {/* <Users /> */}
  </>
  );
}

export default App;
