import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import db from "../../Database/db";
import { Checkbox } from "antd";

const GeneralTab = () => {
  const [data, setData] = useState({});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  useEffect(() => {
    const organizationData = async (id) => {
      const organizationID = await db.organization.get(parseInt(id));
      setData(organizationID);
    };
    organizationData(id);
  }, [id]);

  console.log(data);

  return (
    <>
      <div style={{ margin: "10px" }}>
        <Form>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Organization name:</label>
              <Input
                type="text"
                value={data?.nom}
                //   onChange={(ev) => setName(ev.target.value)}
                style={{ marginTop: "10px" }}
              />
            </Col>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Type:</label>
              <Select
                type="text"
                value={data?.type_details?.name}
                style={{ width: "100%", marginTop: "10px" }}
              >
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </Select>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Phone number:</label>
              <InputNumber
                type="text"
                value={data?.portable_responsable_legal}
                //   onChange={(ev) => setName(ev.target.value)}
                style={{ width: "100%", marginTop: "10px" }}
              />
              <Checkbox onChange={onChange} style={{ marginTop: "8px" }}>
                Whatsapp
              </Checkbox>
            </Col>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Relation:</label>
              <Input
                type="email"
                value={data?.is_light === 0 ? "Sous" : "Prev"}
                //   onChange={(ev) => setEmail(ev.target.value)}
                style={{ marginTop: "10px" }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Country:</label>
              <Select
                type="text"
                value={"Senegal"}
                // onChange={(ev) => setAge(Number(ev.target.value))}
                style={{ width: "100%", marginTop: "10px" }}
              >
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </Select>
            </Col>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}> Address: </label>
              <Input
                type="text"
                value={data?.adresse}
                //   onChange={(ev) => setName(ev.target.value)}
                style={{ width: "100%", marginTop: "10px" }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Email:</label>
              <Input
                type="email"
                value={data?.email}
                //   onChange={(ev) => setAge(Number(ev.target.value))}
                style={{ width: "100%", marginTop: "10px" }}
              ></Input>
            </Col>
            <Col span={8}>
              <label style={{ fontWeight: "bold" }}>Other Email:</label>
              <Input
                type="text"
                value={data?.adresse}
                //   onChange={(ev) => setName(ev.target.value)}
                style={{ width: "100%", marginTop: "10px" }}
              />
            </Col>
            <Button type="primary" style={{ marginTop: "20px" }}>
              Add
            </Button>
          </Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ alignItems: "center", marginTop: "36px" }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default GeneralTab;
