// db.js
import Dexie from "dexie";

const addDB = new Dexie("offlineDataDB");

addDB.version(1).stores({
  offlineData: "++id, data, isSync",
});

export default addDB;
