import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Row } from "antd";

const Pricing = () => {
  const items = [
    {
      id: 3,
      name: "hors-category",
      status: 1,
      added_by: null,
      updated_by: null,
      createdAt: null,
      updatedAt: null,
    },
    {
      id: 2,
      name: "publique",
      status: 1,
      added_by: null,
      updated_by: null,
      createdAt: null,
      updatedAt: null,
    },
    {
      id: 1,
      name: "privé",
      status: 1,
      added_by: null,
      updated_by: null,
      createdAt: null,
      updatedAt: null,
    },
  ];

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.id}>{item.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <h4>Nouvelle grille</h4>
      <Dropdown overlay={menu}>
        <Button style={{ width: "200px" }}>
          <DownOutlined />
        </Button>
      </Dropdown>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "25px",
          marginBottom: "20px",
        }}
      >
        <Button>Back</Button>
        <Button style={{ marginRight: "10px" }} type="primary">
          Save
        </Button>
      </Row>
    </>
  );
};

export default Pricing;
